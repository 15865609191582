import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { OCRStartStatuses, SnackbarSeverity } from 'core/constants/common';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { fetchExamOrderVestingData } from 'core/features/examOrderVesting/examOrderVestingSlice';
import { fetchExamOrderLegalDescriptionData } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';

import {
    IItems,
    IOCRResultsData,
    IRelativeCoord,
    CurrentOCRStatus,
    OCRResultFile,
    GroupedByPage
} from 'types/dataModels';

export interface OcrDataState {
    ocrData: GroupedByPage;
    itemsData: IItems[];
    aliasesData: {};
    currentOcrPageIndex: number;
    pagesData: OCRResultFile[];
    relativeCoordinatesData: IRelativeCoord[];
    currentOCRStatus: CurrentOCRStatus;
    isOCREditModalOpen: boolean;
    currentOcrImageString: string;
}

const initialState: OcrDataState = {
    ocrData: [],
    itemsData: [],
    aliasesData: {},
    pagesData: [],
    relativeCoordinatesData: [],
    currentOcrPageIndex: 1,
    currentOCRStatus: {
        id: '',
        status: OCRStartStatuses.idle
    },
    isOCREditModalOpen: false,
    currentOcrImageString: ''
};

export const ocrDataSlices = createSlice({
    name: 'ocrData',
    initialState,
    reducers: {
        setOcrObjectData(state, action: PayloadAction<IOCRResultsData>) {
            state.itemsData = action.payload.Items;
            state.aliasesData = action.payload.Aliases;
            state.ocrData = action.payload.OCR;
            state.pagesData = action.payload.Pages;
            state.relativeCoordinatesData = action.payload.relativeCoordinates;
            state.currentOcrPageIndex = action.payload.currentOcrPageIndex;
        },
        setCurrentOCRStatus(state, action: PayloadAction<CurrentOCRStatus>) {
            state.currentOCRStatus = action.payload;
        },
        setOCRData(state, action: PayloadAction<GroupedByPage>) {
            state.ocrData = action.payload;
        },
        setCurrentOCRPage(state, action: PayloadAction<number>) {
            state.currentOcrPageIndex = action.payload;
        },
        setCurrentOCRPngPageData(state, action: PayloadAction<OCRResultFile[]>) {
            state.pagesData = action.payload;
        },
        setItemsData(state, action: PayloadAction<IItems[]>) {
            state.itemsData = action.payload;
        },
        setOCREditModalOpen(state, action: PayloadAction<boolean>) {
            state.isOCREditModalOpen = action.payload;
        },
        setCurrentOcrImageString(state, action: PayloadAction<string>) {
            state.currentOcrImageString = action.payload;
        }
    }
});

export const updateLegalInstrumentValue =
    (orderId: string, documentId: string, updatedValue: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.ocrData.updateLegalInstrument(orderId, documentId, updatedValue);
            await dispatch(fetchExamOrderLegalDescriptionData(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Could not update legal instrument value`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const updateVestingInstrumentValue =
    (
        orderId: string,
        documentId: string,
        updatedValue: string,
        IsTagged: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        try {
            await api.ocrData.updateVestingInstrument(
                orderId,
                documentId,
                updatedValue,
                IsTagged
            );
            await dispatch(fetchExamOrderVestingData(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Could not update vesting instrument value`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const {
    setOcrObjectData,
    setCurrentOCRStatus,
    setOCRData,
    setCurrentOCRPage,
    setCurrentOCRPngPageData,
    setItemsData,
    setOCREditModalOpen,
    setCurrentOcrImageString
} = ocrDataSlices.actions;

export default ocrDataSlices.reducer;
