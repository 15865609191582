import { VestingValue, ValueDocLink } from 'types/dataModels';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk, RootState } from 'core/store/store';
import {
    updateParagraph,
    getAllExceptionsAndRequirementsThunk
} from 'core/features/exceptions/exceptionsSlice';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { setWorkbenchExamOrderReviewState } from 'core/features/workbenchTabs/workbenchTabsSlice';

import { SnackbarSeverity } from 'core/constants/common';

interface ExamOrderVestingState {
    examOrderVesting: VestingValue[];
}

const initialState: ExamOrderVestingState = {
    examOrderVesting: []
};

const examOrderVestingSlice = createSlice({
    name: 'examOrderVesting',
    initialState,
    reducers: {
        /**
         * Set vesting data from BE to state
         * @param state Slice state
         * @param action Payload with the list of vesting instruments to set
         */
        setExamOrderVestingData(
            state: ExamOrderVestingState,
            action: PayloadAction<VestingValue[]>
        ) {
            state.examOrderVesting = action.payload;
        },
        /**
         * Set hasPulseFiles flag of document vesting data on document image upload OR delete
         * @param state Slice state
         * @param action Payload with document Id and hasPulseFile flag
         */
        setHasPulseFilesFlagOfVestingData(
            state: ExamOrderVestingState,
            action: PayloadAction<{
                docId: string;
                hasPulseFiles: boolean;
            }>
        ) {
            const vestingOrderDataToUpdate = state.examOrderVesting.find(
                (vestingData) => vestingData?.documentId === action.payload.docId
            );
            if (vestingOrderDataToUpdate)
                vestingOrderDataToUpdate.hasPulseFiles = action.payload.hasPulseFiles;
        }
    }
});

export const { setExamOrderVestingData, setHasPulseFilesFlagOfVestingData } =
    examOrderVestingSlice.actions;

/**
 * Fetch exam order vesting data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrderVestingData =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderVesting.getExamOrderVesting(orderId);
            dispatch(setExamOrderVestingData(response));
        } catch (err) {
            dispatch(setExamOrderVestingData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order vesting data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Apply exam order vesting data
 * @param {string} orderId ID of the order
 * @param {string} vestingId ID of the instrument to apply as order vesting
 * @param {string} instrumentString - Instrument string value,
 * @param {ValueDocLink[]} linkArray - attached document array,
 * @returns {AppThunk}
 */
export const applyExamOrderVestingThunk =
    (
        orderId: string,
        vestingId: string,
        instrumentString: string,
        linkArray?: ValueDocLink[]
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderVesting.applyInstrumentAsOrderVesting(
                orderId,
                vestingId,
                instrumentString,
                linkArray
            );
            dispatch(setExamOrderVestingData(response.vestings));
            dispatch(setWorkbenchExamOrderReviewState(response.reviewState));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Apply exam order vesting: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Save final exam order vesting data
 * @param {string} orderId ID of the order
 * @param {string} examValue Value to save as order vesting
 * @param {boolean} IsTagged Value to save flag
 * @param {ValueDocLink[]} examValueDocLinks array of document links within text
 * @returns {AppThunk}
 */
export const applyFinalExamOrderVestingThunk =
    (
        orderId: string,
        examValue: string,
        IsTagged: boolean,
        examValueDocLinks: ValueDocLink[]
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderVesting.saveFinalOrderVesting(
                orderId,
                examValue,
                IsTagged,
                examValueDocLinks
            );

            dispatch(setExamOrderVestingData(response.vestings));
            dispatch(setWorkbenchExamOrderReviewState(response.reviewState));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            // update paragraphs here
            // I am leaving this as simply the first paragraph returned
            // Because I do not want to invite more blocking items at this time.
            // If we beleive that multiple paragraphs may be returned at some point then
            // We can look for a better solution to this
            if (response.updatedParagraphs) {
                const pay = {
                    id: response.updatedParagraphs[0]?.id,
                    paragraph: response.updatedParagraphs[0]
                };
                dispatch(updateParagraph(pay));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Save final exam order vesting: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export const selectTaggedVestingInstruments = createSelector(
    (state: RootState) => state.examOrderKeyDocumentGroupData.examOrderDocumentGroup,
    (state: RootState) => state.examOrderVestingData.examOrderVesting,
    (documentGroups, vestingInstruments) => {
        const taggedDocIds = documentGroups
            .flatMap((docGroup) => docGroup.documents)
            .filter((doc) => doc.isTagged)
            .map((doc) => doc.id);
        return vestingInstruments.filter((instrument) =>
            taggedDocIds.includes(instrument.documentId)
        );
    }
);

export default examOrderVestingSlice.reducer;
